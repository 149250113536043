<template>
  <div>
    <van-nav-bar
    title="产品列表"
    left-arrow
    @click-left="onGoBack"
    />
    <van-tabs v-model:active="active">
      <van-tab title="全部">
        <van-checkbox-group class="card-goods" v-model="checkedGoods">
          <div 
          style=""
            v-for="item in goods"
            :key="item.id"
            >
            <van-checkbox
              :name="item.id"
              style="width:10%;block:inline;float:left;position: relative;z-index:9999;margin-left:10px;margin-top:25px"
            >
            </van-checkbox>
              <van-card
                style="block:inline"
                :title="item.title"
                :desc="item.desc"
                :price="formatPrice(item.price)"
                :thumb="item.thumb"
              >
              <template #num>
                <van-stepper name="item.id" v-model="item.num" theme="round" button-size="22" step="1"  min="0"  @change="onChangeNum(item)" disable-input />
                <div><span style="font-size:14px">库存：{{ item.num }}</span> </div>
              </template>
              </van-card>
          </div>
        </van-checkbox-group>

        <van-submit-bar
          :price="totalPrice"
          :disabled="!checkedGoods.length"
          :button-text="submitBarText"
          @submit="onSubmit"
        />
      </van-tab>
      <van-tab title="分类">内容 2</van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Checkbox, CheckboxGroup,  SubmitBar, Toast} from 'vant';
import axios from 'axios'
import { useUserStore } from '@/store/user.ts'
import {loginout} from '@/service/user'
const userStore=useUserStore()
export default {
  components: {
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  data() {
    return {
      checkedGoods: [],
      goods: []
    };
  },
  
  mounted(){
    this.GetProData();
    //this.getstatus_userpassword();
  },
  computed: {
    submitBarText() {
      const count = this.checkedGoods.length;
      return '结算' + (count ? `(${count})` : '');
    },

    totalPrice() {
      return this.goods.reduce((total, item) => total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price*item.num : 0), 0);
    }
  },

  methods: {
    
    getstatus_userpassword(){
      axios.post(process.env.VUE_APP_API_PORT_BASE_URL+'/user/checkUserAccount',
      {
        userLoginId:'18826626191'
      }).then(res=>{
        console.log(res)
      }).catch(err=>{
        console.log('err'+res)
      })
      //isexist_password.value=true
    },

    GetProData(){
      let params = {data: {}}
      params={'Authorization':userStore.userInfo.token}
      axios.get(process.env.VUE_APP_API_PORT_BASE_URL+'/prod/getProdInfo',{headers:params})
      .then(res=>{
        if(res.data.length==0){
          loginout()
          this.$toast('登录已失效，请重新登录！')
        }
        this.goods = [];
        console.log(res);
        for (var i=0;i<res.data.data.length;i++) {
          console.log(res.data.data[i]);
          this.goods.push({
          id:i,
          title:res.data.data[i].proName,
          proc_id: res.data.data[i].productId,
          price: res.data.data[i].proPrice*100,
          num:0,
          thumb: process.env.VUE_APP_API_PORT_BASE_URL+res.data.data[i].proPic,
          //proc_class: res.data.data.data[i].userJoinDatetime
          })
        }
      }).catch(err=>{
          console.log(err)
        })
    },

    add:function(){
      if(this.num>=0){
        this.num++;
      }
    },
    /*sub:function(var){
      if(var<0||this.num==1){
        alert("已经是最少了");
      }else{
        this.num--;
      }
    },*/
    onChangeNum(item){
      console.log(item.id,item.num)
      if(item.num>=1 && this.checkedGoods.indexOf(item.id) == -1 ){
        this.checkedGoods.push(item.id)
      }
      if(item.num==0 && this.checkedGoods.indexOf(item.id) != -1){
        var index = this.checkedGoods.indexOf(item.id); 
        if (index > -1) { 
         this.checkedGoods.splice(index, 1); 
        }
      }
    },
    sub(val){
      if(val<0||val==1){
        alert("已经是最少了");
      }else{
        val--;
      }
    },
    formatPrice(price) {
      return (price / 100).toFixed(2);
    },

    onSubmit() {
      Toast('点击结算');
    }
  }
};
</script>

<style lang="less">


.card-goods {
  padding: 5px 0;
  background-color: #fff;
  &__item {
    //float:left;
    //line-height: center;
    //position: relative;
    //background-color: #fafafa;

    /*.van-checkbox__label {
      width: 15%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: 10px;
    }*/

    .van-card__price {
      color: #f44;
    }
  }
}
</style>
